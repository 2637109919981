import Vue from 'vue'
import App from './App.vue'
// import Chat from '/Users/mopeiji/Desktop/test/vue-beautiful-chat/dist/vue-beautiful-chat.umd.min.js'
import Chat from '@/common/vue-beautiful-chat.umd.min.js'
Vue.use(Chat)
Vue.config.productionTip = false
import i18n from "./i18n"
import router from './router';
import navHeader from './components/navHeader.vue'
import ChatComponent from "./components/chat.vue";
Vue.component(navHeader.name, navHeader);
Vue.component(ChatComponent.name, ChatComponent);
import ElementUI from 'element-ui'
Vue.use(ElementUI);
import store from './store'
// import { Form, FormItem, Loading, Select } from 'element-ui';
// Vue.use(Select);
// Vue.use(Loading)
// Vue.use(Form);
// Vue.use(FormItem);

if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = function () {}
  }
}
new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
