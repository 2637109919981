<template>
  <div class="index-warp" v-loading="showLoading">
    <div class="index-box">
      <navHeader />
      <div class="order-warp" v-if="ruleForm.status && !showLoading">
        <h1 class="order-title">
          {{ $t('orderInfo.submitForm.title') }}
        </h1>
        <div class="order-list">
          {{ $t('orderInfo.submitForm.des') }}
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" class="order-form">
          <el-form-item :label="$t('orderInfo.submitForm.form.typeLable')" prop="type">
            <el-select class="select-box" v-model="ruleForm.type"
              :placeholder="$t('orderInfo.submitForm.form.typePlaceholder')">
              <el-option v-for="(item, index) in typeList" :key="index" :label="item.key" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('orderInfo.submitForm.form.reasonLable')" prop="reason">
            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }"
              :placeholder="$t('orderInfo.submitForm.form.reasonPlaceholder')" v-model="ruleForm.reason"></el-input>
          </el-form-item>
          <el-form-item :label="$t('orderInfo.submitForm.form.attachmentListLabel')" prop="attachmentList">
            <el-upload :http-request="UploadImage" list-type="picture-card" :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove" ref="imageList">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button v-loading="btnLoading" class="submit" round size="medium" type="primary" @click="submitForm()">
              {{ $t('orderInfo.submitForm.form.btn') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="success-warp" v-if="!ruleForm.status && !showLoading">
        <img src="/images/success.png" />
        <div class="content-warp">
          {{ $t('orderInfo.successInfo.text') }}
        </div>
        <el-button class="submit" size="medium" round type="primary" @click="goHome">{{
          $t('orderInfo.successInfo.btn') }}</el-button>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :width="isMobileDevice ? '100%' : '30%'">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { postOrderInfo, getOrderInfo, putSignedCode, getSignedCode } from "@/api/index";
import axios from "axios"

export default {
  name: "Order",
  data() {
    return {
      loading: true,
      btnLoading: false,
      showLoading: true,
      ruleForm: {
        status: true,
        orderCode: '',
        attachmentList: [],
        reason: '',
        type: '',
      },
      dialogVisible: false,
      dialogImageUrl: '',
      typeList: this.$t('orderInfo.submitForm.list'),
      rules: {
        type: [
          { required: true, message: this.$t('orderInfo.submitForm.form.typePlaceholder'), trigger: ['change', 'blur'] }
        ],
        reason: [
          { required: true, message: this.$t('orderInfo.submitForm.form.reasonPlaceholder'), trigger: ['change', 'blur'] }
        ],
        attachmentList: [
          { type: 'array', required: true, message: this.$t('orderInfo.submitForm.form.attachmentListPlaceholder'), trigger: 'change' }
        ],
      }
    };
  },
  components: {
  },
  computed: {
    isMobileDevice() {
      return /Mobi|Android/i.test(navigator.userAgent);
    }
  },
  mounted() {
    this.ruleForm.orderCode = this.$route.params.id
    this.$nextTick(() => {
      getOrderInfo({ orderCode: this.$route.params.id }).then(res => {
        this.loading = false
        const result = res.obj
        if (!(result.mediationStage === 'FIRST' && result.mediationStatus === 'WAITING' && result.notificationTime <= new Date().getTime())) {
          this.ruleForm.status = false;

        }
        this.showLoading = false;
      })
    })
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    getFileExtension(filename) {
      return filename.split('.').pop();
    },
    UploadImage(param) {
      const pathKey = `dispute/evidence/${this.$route.params.id}/${new Date().getTime()}.png`
      this.ruleForm.attachmentList.push({
        name: pathKey,
        url: param.file
      })
      param.onSuccess()
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.attachmentList = fileList
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    generateRandomString(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    async uploadimage() {
      const list = this.$refs.imageList.uploadFiles;
      let result = []
      for (let index = 0; index < list.length; index++) {
        const res = list[index];
        const file = res.raw;
        const pathKey = `dispute/evidence/${this.$route.params.id}/${this.generateRandomString(6)}.${this.getFileExtension(file.name)}`
        const { data } = await getSignedCode({ pathKey })
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' }
        };
        await axios.put(data, file, config)
        result.push(pathKey)
      }
      this.ruleForm.attachmentList = result
    },
    async submitForm() {
      if (this.ruleForm.status === false) {
        return;
      }
      this.btnLoading = true
      if (this.$refs.imageList.uploadFiles.length > 0) {
        await this.uploadimage()
      } else {
        this.ruleForm.attachmentList = []
      }
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          this.btnLoading = false;
          return
        }
        postOrderInfo(this.ruleForm).then(res => {
          this.btnLoading = false;
          this.ruleForm.status = false;
        })
      });
    }
  },
};
</script>
<style lang="less">
.el-form-item__label {
  font-weight: bold;
}

.success-warp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  height: 80vh;
  text-align: center;

  img {
    max-width: 300px;
    width: 50%;
  }

  .submit {
    width: 100%;
    max-width: 300px;
  }
}

@media only screen and (min-width: 751px) {
  .success-warp {
    max-width: 600px;
    margin: 0 auto;

    img {
      width: 150px !important;
    }
  }

  .order-warp {
    max-width: 800px;
    padding: 0 20px;
    padding-left: 40px;

    .order-title {
      font-family: Roboto, Roboto;
      font-weight: 500;
      // font-size: 24px;
      color: #1f2021;
    }

    .order-form {
      width: 100%;
      border-radius: 30px;
      background: #F1F8F8;

      .select-box {
        width: 100%;
      }
    }

    .order-list {
      padding-top: 16px;
      font-family: Roboto, Roboto;
      font-weight: 400;
      font-size: 18px;
      color: #1f2021;
    }
  }
}

@media only screen and (max-width: 750px) {
  .order-warp {
    width: calc(100% - 15px);

    .order-title {
      font-family: Roboto, Roboto;
      font-weight: 500;
      // font-size: 30px;
      color: #1f2021;
    }

    .order-list {
      padding-top: 0.12rem;
      font-family: Roboto, Roboto;
      font-weight: 400;
      font-size: 15px;
      color: #1f2021;
    }

    .order-form {
      width: 100%;
      border-radius: 0.3rem;
      padding: 0.2rem 0;
      margin-top: 0.3rem;

      .select-box {
        width: 100%;
      }

      .submit {
        width: 100%;
      }
    }
  }
}
</style>