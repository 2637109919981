export default {
 red: {
  header: {
   bg: '#D32F2F',
   text: '#fff'
  },
  launcher: {
   bg: '#fff'
  },
  messageList: {
   bg: '#fff'
  },
  sentMessage: {
   bg: '#F44336',
   text: '#fff'
  },
  receivedMessage: {
   bg: '#eaeaea',
   text: '#222222'
  },
  userInput: {
   bg: '#fff',
   text: '#212121'
  },
  userList: {
   bg: '#fff',
   text: '#212121'
  }
 },
 green: {
  header: {
   bg: '#388E3C',
   text: '#fff'
  },
  launcher: {
   bg: '#388E3C'
  },
  messageList: {
   bg: '#fff'
  },
  sentMessage: {
   bg: '#4CAF50',
   text: '#fff'
  },
  receivedMessage: {
   bg: '#eaeaea',
   text: '#222222'
  },
  userInput: {
   bg: '#fff',
   text: '#212121'
  },
  userList: {
   bg: '#fff',
   text: '#212121'
  }
 },
 blue: {
  header: {
   bg: '#4e8cff',
   text: '#ffffff'
  },
  launcher: {
   bg: '#4e8cff'
  },
  messageList: {
   bg: '#ffffff'
  },
  sentMessage: {
   bg: '#0096FF',
   text: '#fff'
  },
  receivedMessage: {
   bg: '#F5F5F5',
   text: '#36476C'
  },
  userInput: {
   bg: '#fff',
   text: '#565867'
  },
  userList: {
   bg: '#fff',
   text: '#212121'
  }
 },
 dark: {
  header: {
   bg: '#34495e',
   text: '#ecf0f1'
  },
  launcher: {
   bg: '#34495e'
  },
  messageList: {
   bg: '#2c3e50'
  },
  sentMessage: {
   bg: '#7f8c8d',
   text: '#ecf0f1'
  },
  receivedMessage: {
   bg: '#95a5a6',
   text: '#ecf0f1'
  },
  userInput: {
   bg: '#34495e',
   text: '#ecf0f1'
  },
  userList: {
   bg: '#2c3e50',
   text: '#ecf0f1'
  }
 }
}