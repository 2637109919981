<template>
  <div :style="{ background: backgroundColor }">
    <beautiful-chat
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :close="closeChat"
      :colors="colors"
      :is-open="isChatOpen"
      :message-list="messageList"
      :message-styling="messageStyling"
      :new-messages-count="newMessagesCount"
      :on-message-was-sent="onMessageWasSent"
      :open="openChat"
      :showLauncher="false"
      :participants="participants"
      :show-close-button="true"
      :show-emoji="false"
      :show-file="false"
      :show-typing-indicator="showTypingIndicator"
      :show-edition="false"
      :show-deletion="false"
      :disableUserListToggle="true"
      :title="logoName"
      :placeholder="$t('chat.placeholder')"
      :title-image-url="titleImageUrl"
      :disable-user-list-toggle="false"
    >
      <template v-slot:text-message-body="{ message }">
        <div class="sc-message--text-content" v-html="message.data.text"></div>
      </template>
    </beautiful-chat>
    <img
      @click="openChat('')"
      src="@/assets/custom_icon.png"
      alt=""
      class="ic_comment"
    />
    <el-dialog
      :title="$t('chat.order.title')"
      :visible.sync="dialog.show"
      width="30%"
      class="dialog-warp"
    >
      <div class="dialog-box">
        <div class="sub-title">
          {{ $t("chat.order.subTitle") }}
        </div>
        <div class="order-info-list">
          <div class="order-info-box">
            <div>
              {{ $t("chat.order.info.name") }}：<span>{{
                dialog.orderInfo.name
              }}</span>
            </div>
            <div>
              {{ $t("chat.order.info.price") }}：<span>{{
                dialog.orderInfo.price
              }}</span>
            </div>
            <div>
              {{ $t("chat.order.info.email") }}：<span>{{
                dialog.orderInfo.email
              }}</span>
            </div>
            <div>
              {{ $t("chat.order.info.refund") }}：<span>{{
                dialog.orderInfo.refundPrice
              }}</span>
            </div>
            <div>
              {{ $t("chat.order.info.time") }}：<span>{{
                dialog.orderInfo.time
              }}</span>
            </div>
          </div>
        </div>
        <div class="checked-box">
          <el-checkbox v-model="dialog.checked"></el-checkbox>
          <span class="checkbox-title">{{
            $t("chat.order.info.checkedTitle")
          }}</span>
        </div>
        <div class="btn" @click="submit"> {{ $t("chat.order.info.btn") }}</div>
        <div class="error" v-if="!dialog.checked">
          {{ $t("chat.order.info.tips") }}
        </div>
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="dialogSuccess.show" class="dialog-warp">
      <div class="dialog-box">
        <div class="dialog-success-box">
          <img class="icon" src="@/assets/success/pay_success_icon.png" />
          <div class="title">
            {{ $t("chat.order.orderSuccess.title") }}
          </div>
          <div class="scoped-box">
            <div class="scoped-title">
              {{ $t("chat.order.orderSuccess.rateTitle") }}
            </div>
            <div class="scoped-item">
              <div
                v-for="(item, index) in 5"
                @click="checked(item)"
                :key="index"
              >
                <img
                  v-if="item > dialogSuccess.scope"
                  src="@/assets/success/defule_scope.png"
                  alt=""
                />
                <img v-else src="@/assets/success/scope.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import availableColors from "@/utils/colors";
import { getService } from "@/api/index";
import { mapState } from "vuex";
export default {
  name: "Chat",
  components: {},
  props: {
    title: {
      type: String,
      default: "Help",
    },
  },
  data() {
    return {
      dialog: {
        show: false,
        checked: false,
        orderInfo: {
          name: "",
          price: "",
          email: "",
          time: "",
          refundPrice: "",
        },
      },
      dialogSuccess: {
        show: false,
        scope: 0,
      },
      participants: [
        {
          id: "support",
          name: "support",
          imageUrl: require("@/assets/ic_customer.png"),
        },
      ],
      titleImageUrl: require("@/assets/logo.png"),
      messageList: [],
      newMessagesCount: 0,
      isChatOpen: false,
      showTypingIndicator: "",
      colors: null,
      availableColors,
      chosenColor: null,
      alwaysScrollToBottom: true,
      messageStyling: true,
      userIsTyping: false,
    };
  },
  computed: {
    ...mapState(["logoName", "domainInfo"]),
    linkColor() {
      return this.chosenColor === "dark"
        ? this.colors.sentMessage.text
        : this.colors.launcher.bg;
    },
    backgroundColor() {
      return this.chosenColor === "dark" ? this.colors.messageList.bg : "#fff";
    },
  },
  created() {
    this.setColor("blue");
  },
  mounted() {},
  methods: {
    //TODO 评分
    checked(item) {
      this.dialogSuccess.scope = item;
      let messageId = localStorage.getItem("messageId");
      getService({
        supportCode: this.domainInfo.code,
        request: `scope:${item}`,
        chatId: messageId,
      });
    },
    submit() {
      if (!this.dialog.checked) {
        this.dialog.checked = true;
        return;
      }
      this.sendMessage("User agrees to refund");
      this.dialog = {
        show: false,
        checked: false,
        orderInfo: {
          name: "",
          price: "",
          email: "",
          time: "",
          refundPrice: "",
        },
      };
      this.dialogSuccess = {
        show: true,
        scope: 0,
      };
    },
    sendMessage(text) {
      let messageId = localStorage.getItem("messageId");
      this.handleTyping("text");
      getService({
        supportCode: this.domainInfo.code,
        request: text,
        chatId: messageId,
      })
        .then(async (res) => {
          if (!res.obj) {
            res.obj = {
              responesList: [this.$t("chat.errorChat")],
            };
          }
          if (res.obj.chatId && !localStorage.getItem("messageId")) {
            localStorage.setItem("messageId", res.obj.chatId);
          }
          if (res.obj.responesList.length > 0) {
            for (let index = 0; index < res.obj.responesList.length; index++) {
              if (res.obj.responesList.length > index) {
                this.handleTyping("text");
              }
              await this.delay(1000);
              const text = res.obj.responesList[index];
              let suggestions =
                res.obj.responesList.length - 1 === index
                  ? res.obj.suggestionsList
                  : [];
              this.sendWasSent(text, suggestions);
            }
          }
          if (res.obj.refundOrderUrl) {
            window.location.href = res.obj.refundOrderUrl
            return;
          }
          if (res.obj.refundInformation) {
            const date = new Date(
              res.obj.refundInformation.purchaseTime
            ); // Multiply by 1000 to convert from seconds to milliseconds
            const formattedDate = date
              .toISOString()
              .slice(0, 19)
              .replace("T", " "); // Format to 'YYYY-MM-DD HH:mm:SS'
            this.dialog = {
              show: true,
              checked: false,
              orderInfo: {
                name: res.obj.refundInformation.productTitle,
                price:
                  res.obj.refundInformation.currency +' ' +
                  res.obj.refundInformation.money,
                email: res.obj.refundInformation.email,
                time: formattedDate,
                refundPrice:
                  res.obj.refundInformation.currency +' ' +
                  res.obj.refundInformation.refundMoney,
              },
            };
          }
        })
        .catch(() => {
          this.sendWasSent(this.$t("chat.errorChat"), []);
        });
    },
    handleTyping(text) {
      this.showTypingIndicator =
        text.length > 0
          ? this.participants[this.participants.length - 1].id
          : "";
    },
    async onMessageWasSent(message) {
      this.messageList = [
        ...this.messageList,
        Object.assign({}, message, { id: Math.random() }),
      ];
      localStorage.setItem("messageList", JSON.stringify(this.messageList));
      if (message.author === "me") {
        const defaultChat = this.$t("chat.defaultListChat");
        let next = {
          checked: true,
          text: "",
        };
        Object.keys(defaultChat).forEach((key) => {
          let keyValue = key.replace("{logoName}", this.logoName);
          if (keyValue === message.data.text) {
            next.checked = false;
            next.text = defaultChat[key].map((res) => {
              return res.replace("{logoName}", this.logoName);
            });
          }
        });
        if (next.checked) {
          this.sendMessage(message.data.text);
        } else {
          for (let index = 0; index < next.text.length; index++) {
            if (next.text.length > index) {
              this.handleTyping("text");
              await this.delay(500);
            }
            await this.delay(1000);
            const text = next.text[index];
            this.sendWasSent(text, []);
          }
        }
      }
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    sendWasSent(text, suggestions = []) {
      this.newMessagesCount = this.isChatOpen
        ? this.newMessagesCount
        : this.newMessagesCount + 1;
      this.showTypingIndicator = "";
      let items = {
        author: "support",
        type: "text",
        id: Math.random(),
        data: { text: text },
      };
      if (suggestions.length > 0) {
        items.suggestions = suggestions;
      }
      this.onMessageWasSent(items);
    },
    openChat(text) {
      this.isChatOpen = true;
      this.newMessagesCount = 0;
      if (text) {
        this.sendMessage('');
      } else {
        const list = localStorage.getItem("messageList");
        if (list) {
          this.messageList = JSON.parse(list);
        } else {
          this.messageList = [
            {
              type: "text",
              author: `support`,
              id: 1,
              data: {
                text: this.$t("chat.defaultChat"),
              },
              suggestions: this.$t("chat.defaultSuggestions").map((res) => {
                return res.replace("{logoName}", this.logoName);
              }),
            },
          ];
        }
      }
    },
    closeChat() {
      this.isChatOpen = false;
    },
    setColor(color) {
      this.colors = this.availableColors[color];
      this.chosenColor = color;
    },
  },
};
</script>

<style lang="less">
body {
  padding: 0px;
  margin: 0px;
}

* {
  font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
}

.demo-description {
  max-width: 500px;
}

.demo-description img {
  max-width: 500px;
}

.demo-test-area {
  width: 300px;
  box-sizing: border-box;
}

.demo-test-area--text {
  box-sizing: border-box;
  width: 100%;
  margin: 0px;
  padding: 0px;
  resize: none;
  font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
  background: #fafbfc;
  color: #8da2b5;
  border: 1px solid #dde5ed;
  font-size: 16px;
  padding: 16px 15px 14px;
  margin: 0;
  border-radius: 6px;
  outline: none;
  height: 150px;
  margin-bottom: 10px;
}

.demo-monster-img {
  width: 400px;
  display: block;
  margin: 60px auto;
}

.text-center {
  text-align: center;
}

.colors a {
  color: #fff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 10px;
}

.toggle a {
  text-decoration: none;
}

.messageStyling {
  font-size: small;
}
.sc-launcher {
  width: 50px !important;
  height: 50px !important;
}
.sc-open-icon {
  right: 18px !important;
  bottom: 20px !important;
}
.sc-suggestions-element {
  font-size: 16px !important;
  background: #def3ff;
  padding: 11px 22px !important;
  width: calc(80% - 15px) !important;
  display: inline-block;
}
.sc-message--text {
  border-radius: 0px 12px 12px 12px !important;
}
.dialog-success-box {
  text-align: center;
  .icon {
    width: 102px;
  }
  .title {
    margin: 0 auto;
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    max-width: 300px;
  }
  .scoped-box {
    .scoped-title {
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      text-align: center;
      margin-bottom: 8px;
      padding-top: 12px;
    }
    width: 265px;
    height: 88px;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #eaedee;
    margin: 30px auto;
    .scoped-item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      div {
        cursor: pointer;
        width: 30px;
        height: 30px;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: 751px) {
  .dialog-warp {
    .el-dialog {
      width: 377px !important;
      border-radius: 12px;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
      .el-dialog__title {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .el-dialog__body {
      padding-top: 0;
    }
    .dialog-box {
      .sub-title {
        font-weight: 400;
        font-size: 14px;
        color: #909499;
        margin-bottom: 16px;
      }
      .order-info-list {
        background: #f0f2f3;
        border-radius: 8px 8px 8px 8px;
        .order-info-box {
          font-weight: 400;
          font-size: 14px;
          color: #494b4d;
          padding: 15px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          span {
            color: #1f2021;
          }
        }
      }
      .checked-box {
        display: flex;
        align-items: flex-start;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        gap: 10px;
        margin: 20px 0;
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: #1db25a !important;
          border-color: #1db25a !important;
        }
      }
      .dialog-content {
        .dialog-title {
          font-weight: 600;
          font-size: 18px;
          color: #000000;
          margin-bottom: 17px;
        }
        .dialog-title {
          font-weight: 400;
          font-size: 14px;
          color: #909499;
        }
      }
      .btn {
        width: 260px;
        margin-top: 21px;
        border-radius: 8px;
        text-align: center;
        margin-bottom: 16px;
      }
      .error {
        font-weight: 400;
        font-size: 12px;
        color: #d65858;
        margin-top: 16px;
        text-align: center;
      }
    }
  }
  .sc-message--avatar {
    padding-top: 6px;
  }
  .sc-message--text-content {
    padding: 3px 0;
    font-weight: 500;
    font-size: 14px;
  }
  /* .sc-message {
  width: 350px !important;
} */
  .sc-message-list {
    padding: 15px 0 !important;
  }
  .sc-header {
    min-height: 60px !important;
    padding: 0 !important;
  }
  .sc-header--img {
    height: 44px !important;
    padding: 0 0 0 10px !important;
  }
  .sc-message--content.sent {
    margin-right: 10px;
  }
}
.ic_comment {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 140px;
  height: 140px;
  cursor: pointer;
  z-index: 1;
}
@media (max-width: 750px) {
  .dialog-warp {
    .el-dialog {
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      border-radius: 0 !important;
      .el-dialog__title {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .el-dialog__body {
      padding-top: 0;
    }
    .dialog-box {
      .sub-title {
        font-weight: 400;
        font-size: 14px;
        color: #909499;
        margin-bottom: 16px;
      }
      .order-info-list {
        background: #f0f2f3;
        border-radius: 8px 8px 8px 8px;
        .order-info-box {
          font-weight: 400;
          font-size: 14px;
          color: #494b4d;
          padding: 15px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          span {
            color: #1f2021;
          }
        }
      }
      .checked-box {
        display: flex;
        align-items: flex-start;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        gap: 10px;
        margin: 20px 0;
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: #1db25a !important;
          border-color: #1db25a !important;
        }
      }
      .dialog-content {
        .dialog-title {
          font-weight: 600;
          font-size: 18px;
          color: #000000;
          margin-bottom: 17px;
        }
        .dialog-title {
          font-weight: 400;
          font-size: 14px;
          color: #909499;
        }
      }
      .btn {
        width: 260px;
        margin-top: 21px;
        border-radius: 8px;
        text-align: center;
        margin-bottom: 16px;
      }
      .error {
        font-weight: 400;
        font-size: 12px;
        color: #d65858;
        margin-top: 16px;
        text-align: center;
      }
    }
  }
  .ic_comment {
    right: 14px;
    width: 60px;
    height: 60px;
  }
  .sc-message--avatar {
    padding-top: 4px;
  }
  .customize-warp {
    width: 86% !important;
  }
  .sc-header--img {
    width: 40px;
    padding-right: 0 !important;
  }

  .sc-message--text-content {
    padding: 3px 0;
    font-weight: 500;
    font-size: 14px;
  }
  .sc-message {
    width: 94% !important;
  }
}
.sc-message--text {
  padding: 5px 10px !important;
}
.sc-message--avatar {
  align-self: flex-start !important;
  margin-right: 5px !important;
}
.sc-message--content.sent {
  margin-right: 10px;
}
.sc-user-input--text {
  /* padding: 0 !important;
  max-height: 38px !important;
  margin: 18px; */
  max-height: 60px !important;
}
.sc-user-input--text::-webkit-scrollbar {
  width: 5px;
}

.sc-user-input--text::-webkit-scrollbar-thumb {
  border-radius: 999px;
}

.sc-user-input--text::-webkit-scrollbar-track {
  background: transparent;
}

.sc-user-input--text::-webkit-scrollbar-thumb {
  /* min-height: 3px; */
  height: 4px;
  background-clip: content-box;
  background: #00000080;
}

.sc-user-input--text::-webkit-scrollbar-corner {
  background: transparent;
}
.sc-user-input--text::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.sc-message--content.sent .sc-message--text {
  max-width: calc(100% - 60px) !important;
}
.sc-message--content.received .sc-message--text {
  margin-right: 30px !important;
}
</style>
